img.emoji {
	height: 1.3em;
	width: 1.3em;
	margin: 0 .05em 0 .1em;
	vertical-align: -0.1em;
}

.big .emoji {
	height: 40px;
	width: 40px;
}