.select .MuiSelect-outlined.MuiSelect-outlined {
  border-radius: 30px !important;
}

.select .MuiSelect-select:focus {
  border-radius: 30px;
}

.actions-menu{
  position:relative;
  padding:10px 0px;
  margin:0;
  color:#000;
  background:#fff;
  border-radius:10px;
  left: 25px;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
}

.triangle {
  width: 32px;
  height: 20px;
  position: absolute;
  overflow: hidden;
  box-shadow: 0 16px 10px -17px rgba(0, 0, 0, 0.5);
  right: 4px;
  top: -20px;
  z-index: 1;
}
.triangle:after {
  content: "";
  position: absolute;
  width: 22px;
  height: 22px;
  background: #fff;
  transform: rotate(45deg);
  top: 10px;
  left: 5px;
  box-shadow: 0px 1px 5px 1px rgba(0,0,0,0.2), 7px 4px 7px 1px rgba(0,0,0,0.3);
}