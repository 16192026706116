.customQuill .ql-toolbar.ql-snow .ql-formats {
  margin-right: 5px;
  background-color: #eee;
  padding: 3px 12px;
}

.customQuill .ql-toolbar.ql-snow .ql-formats:nth-child(1) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-left: 6px;
}

.customQuill .ql-toolbar.ql-snow .ql-formats:nth-last-child(1) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.customQuill .ql-snow.ql-toolbar button {
  padding: 3px 7px;
  width: 27px;
  height: 26px;
}

.customQuill .ql-toolbar.ql-snow {
  border-width: 0px;
  margin-bottom: 10px;
  padding: 0px;
}

.customQuill .ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-top: 1px solid #ccc;
}

.customQuill .ql-container.ql-snow {
  border-radius: 8px;
}

.emailResponse .customQuill .ql-toolbar.ql-snow {
  margin-bottom: 10px;
}

.select .MuiSelect-outlined.MuiSelect-outlined {
  border-radius: 30px !important;
}

.select .MuiSelect-select:focus {
  border-radius: 30px;
}

#select .MuiSelect-outlined.MuiSelect-outlined {
  border-radius: 30px;
}

.node-body p img {
  max-width: 100%;
}