html, body { 
  height: 100%;
}

.message-bubble {
  background: linear-gradient(164.6deg, #2DC9EB 0%, #14D2B8 100%);
  padding: 7px 10px;
  color: white;
  font-size: 14px;
  margin-right: 5px;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  border-top-left-radius: 0px;
}

.tri-right.left-top::after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -5px;
	right: auto;
  top: 0px;
	bottom: auto;
	border: 6px solid;
	border-color: #2DC9EB transparent transparent transparent;
}

.agent-bubble {
  background: linear-gradient(345.91deg, #645AFF -3.69%, #A573FF 109.82%);;
  padding: 7px 10px;
  color: white;
  font-size: 14px;
  margin-right: 5px;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  border-top-right-radius: 0px;
}

.tri-right.right-top::after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  right: -4px;
	left: auto;
  top: 0px;
	bottom: auto;
	border: 6px solid;
	border-color: #8D6AFF transparent transparent transparent;
}

.agent-bubble p {
  margin: 0px;
}

.mobile-text-entry {
  /* background-color: rgba(25,25,25,0.1); */
  border-radius: 30px;
  border-width: 1px;
  border-color: #C4C4C4;
  border-style: solid;
}

.mobile-text-entry .ql-container {
  font-size: 16px;
}

.mobile-text-entry .ql-editor.ql-blank::before {
  color: 'black';
  font-size: 15px;
}

.mobile-text-entry .ql-editor p {
  font-size: 15px;
}
