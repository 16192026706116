.small-text-field .MuiInputBase-input {
  padding: 12px 6px;
  font-size: 12px;
}

.no-scroll {
  scrollbar-width: none;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}