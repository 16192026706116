
@media print{

  @page { 
    margin: 50;
    size: A2;
  }

  body {
    margin: 25mm;
  }

  #breadcrumbs {
    display: none;
  }
  
  #left-menu-bar {
    display: none;
  }
  
  #page-header {
    padding: 5px;
    box-shadow: none;
    border-bottom: 1px solid rgba(0,0,0,0.7);
    width: 100%;
  }
  
  #dashboard-content {
    padding: 20px 3px 3px 3px;
    background-color: yellow;
  }
  
  #conversion-graph {
    margin-top: -50px;
    height: 380px !important;
  }
  
  #conversion-card-content {
    height: 300px !important;
    padding-bottom: 0px;
  }
  
  #dashboard {
    overflow-y: visible;
    background-color: blue;
  }
  
  #dashboard-page {
    overflow: visible;
    background-color: red;
  }
  
  #main-content {
    overflow: visible;
  }
  
  #main-pages {
    overflow-y: visible;
  }
  
  #page-title {
    color: black;
    font-size: 1.6em;
  }
  
  #secondary-metrics {
    border-bottom: 1px solid rgba(0,0,0,0.7);
  }
  
  #secondary-graph {
    margin-top: -80px;
  }

  #secondary-card-content {
    height: 350px !important;
    padding-bottom: 0px;
  }

  #action-div {
    display: none;
  }

  #date-div {
    display: block !important;
    margin-right: 15px;
    color: rgba(0,0,0,0.7);
    font-size: 1.5em;
    font-family: Poppins, sans serif;
    font-weight: 600;
  }
}
