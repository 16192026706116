.rdrStaticRangeLabel {
  padding: 15px 20px;
}

.rdrInputRanges {
  padding: 0px
}

.rdrDateDisplayWrapper {
  position: relative;
  left: -180px;
  width: 546px;
}