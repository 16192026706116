.autocomplete .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  min-width: 0px;
}

.MuiAutocomplete-listbox li[aria-disabled="true"] {
  pointer-events: all;
}

.MuiAutocomplete-listbox li[aria-disabled="true"]:hover,
.MuiAutocomplete-listbox li[aria-disabled="true"]:focus {
  background: white;
}

.MuiAutocomplete-listbox li[aria-disabled="true"]:active {
  background: white;
  pointer-events: none;
}