body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*
   * "overflow: hidden" causes issues for mobile, and makes it so you can't scroll through
   * conversations. If there's a reason you need to have it hidden, please ensure that you
   * test scrolling on mobile.
  height: 100%;
  width: 100%;
  overflow: hidden;
  */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-focus-outline:focus,
.no-focus-outline:focus-visible {
  outline: none;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.tailwind-bullet-dot {
  list-style: inside;
}

.tailwind-bullet-number {
  list-style: decimal inside;
}