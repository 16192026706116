.CalendarSync__providers a:nth-child(2) {
  display: none;
}

.CalendarSync {
  display: grid !important;
  grid-template-rows: 20px auto;
  grid-template-areas: 
  "footer" "profiles";
  min-height: 10px !important;
}

.CalendarSync__footer {
  display: flex;
  justify-content: flex-start !important;
  grid-area: footer;
  margin-top: 0px !important;
}

.CalendarSync__title {
  grid-area: title;
  border-bottom: none !important;
  padding-bottom: 3px !important;
  display: none;
}

.CalendarSync__profiles {
  grid-area: profiles;
  border-top: 1px solid rgb(216, 216, 216);
}

.CalendarSync__status--active {
  grid-area: profiles;
}

.CalendarSync__status--active>p {
  display: none;
}

.ModalCalendar__providers a:nth-child(2) {
  display: none;
}

.ModalCalendar__status--active>p {
  display: none;
}

.ModalCalendar__status--active {
  border-bottom: none !important;
}

.ModalCalendar__title {
  display: none;
}

.ModalCalendar__providers {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  padding: 0px 15px;
}

.ModalCalendar__providers a {
  width: 100%;
  margin: 0px;
}