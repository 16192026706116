.jtk-drag-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: grabbing;
}

.jtk-surface-pan {
  display: none;
}

.jtk-surface {
  background: #F1E9F8;
  /* background: rgba(0,0,0,0.02); */
  background-image: radial-gradient(rgba(0,0,0,0.2) 1px, transparent 0);
  background-size: 40px 40px;
  background-position: -21px -21px;
}

#surface-container-sequence .jtk-surface {
  /* background: rgb(30,60,90); */
  background-image: radial-gradient(rgba(255,255,255,0.5) 1px, transparent 0);
  background-size: 40px 40px;
  background-position: -21px -21px;
}

.jtk-overlay {
  border-radius: 50px; 
  background-color: #F0706F; 
  height: 30px; 
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  visibility: hidden;
}

.jtk-overlay.add-relationship {
  margin-top: -20px;
}

.jtk-overlay.delete-relationship {
  margin-top: 20px;
}

.jtk-overlay.delete-relationship.jtk-hover {
  visibility: visible;
}

.jtk-overlay.add-relationship.jtk-hover {
  visibility: visible;
}

.connector {
  overflow: visible;
  cursor: default;
}

.jtk-connector-outline {
  stroke-width: 15px;
}

@keyframes dash {
  to {
    stroke-dashoffset: -600;
  }
}

.jtk-connector.jtk-hover>:nth-child(2) {
  stroke-dasharray: 20;
  animation-iteration-count: infinite;
  animation: dash 8s linear;
}

.jtk-connector.connector.jtk-hover path {
  stroke: #F0706F;
}

.jtk-connector.connector.jtk-hover path:nth-child(1) {
  stroke: transparent;
}

.jtk-connector.connector.jtk-hover path:nth-child(3) {
  fill: #F0706F;
}

.jtk-connector.connector.jtk-dragging {
  cursor: grabbing;
}

.jtk-drag-drop-active {
  cursor: grabbing;
}

.jtk-drag-drop-hover {
  cursor: grabbing;
}

.node-port {
  width: 210px; 
  height: 25px;
  position: absolute;
  top: 0px;
  cursor: grab;
}

.node-port.jtk-connected {
  cursor: default;
}

.node-port.jtk-drag-active {
  height: 0px;
}

.jtk-droppable.jtk-drag-hover {
  box-shadow: 0 0 6px 5px rgba(101, 138, 229, .9);
}

.jtk-surface-selected-element {
  border: none !important;
}

.jtk-draggable.jtk-surface-selected-element {
  box-shadow: 0 0 6px 5px rgba(142, 90, 226, .9);
}

.jtk-node:not(.jtk-surface-selected-element):hover {
  box-shadow: 0 0 3px 5px rgba(229, 226, 233, 0.9);
}

.jtk-draggable.base-node {
  cursor: move;
}

.connector path {
  stroke-width: 4px;
  outline-width: 10px;
  outline-color: rgba(0,0,0,0);
  stroke: #658AE5;
}

.connector path:nth-child(3) {
  stroke-width: 3px;
  fill: #658AE5;
}

.connector path:nth-child(1) {
  stroke-width: 60px;
  fill: transparent;
  stroke: transparent;
}
