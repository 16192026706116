.agent-message {
  background-color: #F2F2F2;
  padding: 4px 12px;
  border-radius: 16px;
  margin-top: 3px;
  margin-left: 10px;
  line-height: 22px;
}

.agent-message p {
  margin: 0px;
}